import axios from 'axios';
import { API_ENVIROMENT_URL } from './constants';
import Cookies from 'js-cookie';

// Function to return movement with an specific limit
export const getInitMovements = (id) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + `movement/get/${id}`;
    const headers = {
        'Authorization': Cookies.get('token'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
    }
    axios.get(url, { headers })
        .then((res) => resolve(res.data.data.data))
        .catch((err) => reject(err));
});

export const get = (from_date, to_date, id) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + `movement/get/${id}?page=1&from_date=${from_date}&to_date=${to_date}`;
    const headers = {
        'Authorization': Cookies.get('token'),
        'Content-Type': 'application/json'
    }
    axios.get(url,{ headers: headers})
        .then(response => {
            resolve(response.data.data.data);
        })
        .catch(error => {
            reject(error);
        })
});
