import React, { Fragment } from 'react';
import Header from '../components/Header';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Logo from '../assets/img/logo.png';
import Marco from '../assets/img/marco.png';
import Cookies from 'js-cookie';
import { CUTOFF_DATE_FORMATTED } from '../services/constants';
import { get } from '../services/movements';
import NumberFormat from 'react-number-format';

// Font.register({
//     family: 'oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
        top: -1,
    },
    table: { 
        display: "table", 
        width: "auto",
        paddingLeft:40,
        paddingRight:40,
    },

    // Header
    tableRowHeader: { 
        flexDirection: "row",
        marginTop:20 
    },
    tableColHeader: { 
        width: "50%",
    },
    titleHeader: {
        marginTop:40,
        fontSize:16,
    },
    image: {
        width:'auto',
        height: 60,
        marginHorizontal:80,
    }, 
    // Header

    // User Info
    tableRowInfo: { 
        flexDirection: "row",
        paddingTop:20,
    },
    tableColInfo: { 
        width: "50%",
    }, 
    userInfo: {
        fontSize:11,
        padding:3,
    },
    userInfoBold: {
        fontSize:11,
    },
    // User Info

    // Period
    titlePeriod: {
        fontSize:14,
        color:'white',
        backgroundColor:'#09345B',
        textAlign:'center',
    },
    rowPeriod: {
        backgroundColor:'#F4F2F1',
        flexDirection:'row',
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    rowPeriodSecond: {
        backgroundColor:'#DED7D5',
        flexDirection:'row',
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    sectionPeriod: {
        width:'50%',
        fontSize:11,
        padding:5,
        textAlign:'center',
    },
    firstSectionPeriod: {
        width:'50%',
        fontSize:11,
        padding:5,
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
    },
    // Period

    // Movements
    titleMovement: {
        fontSize:16,
        textAlign:'center',
        backgroundColor:'#09345B',
        color:'white',
    },
    totalMovement: {
        fontSize:12,
        textAlign:'right',
        backgroundColor:'#09345B',
        color:'white',
    },
    tableRowTitleMovement: {
        flexDirection:'row',
        marginTop:20,
    },
    tableRowSubMovement: {
        flexDirection:'row',
        backgroundColor:'#F4F2F1',
        padding:10,
        borderColor:'grey',
        borderWidth: 1, 
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableRowMovementOne: {
        flexDirection:'row',
        padding:5,
        backgroundColor:'#DED7D5',
    },
    tableRowMovementTwo: {
        flexDirection:'row',
        padding:5,
        backgroundColor:'#F4F2F1',
    },
    tableRowTotalMovement: {
        flexDirection:'row',
        backgroundColor:'#F4F2F1',
    },
    tableColMovement: { 
        width: "100%",
    },
    cellMovement: {
        width: "20%",
    },
    cellTitleMovement: {
        fontSize:12,
        textAlign:'center',
    }, 
    // Movements

    // Summary
    titleSummary: {
        fontSize:16,
        textAlign:'center',
        backgroundColor:'#09345B',
        color:'white',
    },
    tableRowTitleSummary: {
        flexDirection:'row',
        marginTop:20,
    },
    tableColSummary: { 
        width: "100%",
    },
    tableRowSummaryOne: {
        flexDirection:'row',
        backgroundColor:'#DED7D5',
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableRowSummaryTwo: {
        flexDirection:'row',
        backgroundColor:'#F4F2F1',
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    cellSummary: {
        width: "50%",
    },
    cellSummaryFirst: {
        width: "50%",
        borderStyle: "solid", 
        borderColor:'grey',
        borderWidth: 1, 
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    cellTitleSummary: {
        fontSize:12,
        textAlign:'center',
        padding:5,
    },
    cellTitleFinalSummary: {
        fontSize:14,
        textAlign:'center',
        padding:5,
    }
    // Summary
});

export default function State(props) {

    const [movements, setMovements] = React.useState([]);
    const userInfo = JSON.parse(Cookies.get('user'));
    const dates = JSON.parse(Cookies.get('dates'));

    const format = (x, y) => {
        var z = {
            M: x.getMonth() + 1,
            d: x.getDate(),
            h: x.getHours(),
            m: x.getMinutes(),
            s: x.getSeconds()
        };
        y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
            return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
        });
    
        return y.replace(/(y+)/g, function(v) {
            return x.getFullYear().toString().slice(-v.length)
        });
    }

    const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        
            const negativeSign = amount < 0 ? "-" : "";
        
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;
        
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    }

    const getTotals = () => {
        let total = 0;
        movements.forEach(item => {
            if (item.type === 'income') {
                total += item.amount
            } else if (item.type === 'egress') {
                total -= item.amount
            } else {
                total += item.amount
            }
        });
        return total;
    }

    const getSubTotals = (type) => {
        let total = 0
        movements.forEach(item => {
            if (item.type === type) {
                total += item.amount
            }
        });
        return total;
    }

    const getFirstIncome = () => {
        let total = 0
        movements.forEach(item => {
            if (item.type === 'income' && total === 0) {
                total += item.amount
            }
        });
        return total;
    }

    React.useEffect(() => {
        console.log('start -> ', dates.startDate);
        console.log('end -> ', dates.endDate);
        get(dates.startDate, dates.endDate, userInfo.id)
            .then(response => {
                console.log(response);
                console.log('user info -> ',userInfo);
                setMovements(response);
            })
            .catch(error => {
                console.log(error);
            });
    },[])
    
    return (
        <Fragment>
            <Header userInfo={userInfo} setPasswordModal={() => {}} history={props.history} />
            <PDFViewer style={{ width:'100%', height:'100%' }}>
                <Document>
                <Page size="A4" style={ styles.body }>
                    <Image style={ styles.pageBackground } src={ Marco } />
                    <View style={ styles.table }> 
                        <View style={ styles.tableRowHeader }> 
                            <View style={ styles.tableColHeader }> 
                                <Text style={ styles.titleHeader }>Estado de cuenta</Text>
                            </View> 
                            <View style={ styles.tableColHeader }> 
                                <Image style={ styles.image } src={ Logo } />
                            </View> 
                        </View>
                        <View style={styles.tableRowInfo}>
                            <View style={styles.tableColInfo}>
                                <Text style={ styles.userInfo }>{userInfo.name}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>RFC:</Text> {userInfo.rfc}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>Domicilio:</Text> {userInfo.street}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>Colonia:</Text> {userInfo.colony}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>País:</Text> {userInfo.country}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>Ciudad:</Text> {userInfo.city}, {userInfo.state}</Text>
                                <Text style={ styles.userInfo }><Text style={ styles.userInfoBold }>C.P:</Text> {userInfo.postal_code}</Text>
                            </View>
                            <View style={ styles.tableColInfo }>
                                <View style={ styles.titlePeriod }>
                                    <Text style={{ padding:5 }}>Información del periodo</Text>
                                </View>
                                <View style={ styles.rowPeriod }>
                                    <View style={ styles.firstSectionPeriod }>
                                        <Text>Periodo seleccionado</Text>
                                    </View>
                                    <View style={ styles.sectionPeriod }>
                                        <Text>{`${format(new Date(dates.startDate), 'dd/MM/yyyy')} - ${format(new Date(dates.endDate), 'dd/MM/yyyy')}`}</Text>
                                    </View>
                                </View>
                                <View style={ styles.rowPeriodSecond }>
                                    <View style={ styles.firstSectionPeriod }>
                                        <Text>Fecha de corte</Text>
                                    </View>
                                    <View style={ styles.sectionPeriod }>
                                        <Text>{format(new Date(CUTOFF_DATE_FORMATTED), 'dd/MM/yyyy')}</Text>
                                    </View>
                                </View>
                                <View style={ styles.rowPeriod }>
                                    <View style={ styles.firstSectionPeriod }>
                                        <Text>Número de cuenta</Text>
                                    </View>
                                    <View style={ styles.sectionPeriod }>
                                        <Text>{userInfo.account_number}</Text>
                                    </View>
                                </View>
                                <View style={ styles.rowPeriodSecond }>
                                    <View style={ styles.firstSectionPeriod }>
                                        <Text>Número de referencia</Text>
                                    </View>
                                    <View style={ styles.sectionPeriod }>
                                        <Text>{userInfo.reference_number}</Text>
                                    </View>
                                </View>
                                <View style={ styles.rowPeriod }>
                                    <View style={ styles.firstSectionPeriod }>
                                        <Text>Número de cliente</Text>
                                    </View>
                                    <View style={ styles.sectionPeriod }>
                                        <Text>{userInfo.customer_number}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowTitleMovement }>
                            <View style={ styles.tableColMovement }>
                                <View style={ styles.titleMovement }>
                                    <Text style={{ padding:5 }}>Movimientos</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSubMovement }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellMovement }>
                                    <Text style={ styles.cellTitleMovement }>Fecha</Text>
                                </View>
                                <View style={ styles.cellMovement }>
                                    <Text style={ styles.cellTitleMovement }>Concepto</Text>
                                </View>
                                <View style={ styles.cellMovement }>
                                    <Text style={ styles.cellTitleMovement }>Egreso</Text>
                                </View>
                                <View style={ styles.cellMovement }>
                                    <Text style={ styles.cellTitleMovement }>Ingreso</Text>
                                </View>
                                <View style={ styles.cellMovement }>
                                    <Text style={ styles.cellTitleMovement }>Rendimientos</Text>
                                </View>
                            </View>
                        </View>
                        { movements.map((item,index) => {
                            return (
                                <View key={index} style={ (index%2) ? styles.tableRowMovementTwo : styles.tableRowMovementOne }>
                                    <View style={{ flexDirection:'row', }}>
                                        <View style={ styles.cellMovement }>
                                            <Text style={ styles.cellTitleMovement }>{format(new Date(item.date), 'dd/MM/yyyy')}</Text>
                                        </View>
                                        <View style={ styles.cellMovement }>
                                            <Text style={ styles.cellTitleMovement }>{item.concept}</Text>
                                        </View>
                                        <View style={ styles.cellMovement }>
                                            <Text style={ styles.cellTitleMovement }>{(item.type === 'egress') 
                                            ? `$ ${formatMoney(item.amount)}`
                                            : ''}</Text>
                                        </View>
                                        <View style={ styles.cellMovement }>
                                            <Text style={ styles.cellTitleMovement }>{(item.type === 'income') 
                                            ? `$ ${formatMoney(item.amount)}`
                                            : ''}</Text>
                                        </View>
                                        <View style={ styles.cellMovement }>
                                            <Text style={ styles.cellTitleMovement }>{(item.type === 'yield') 
                                            ? `$ ${formatMoney(item.amount)}`
                                            : ''}</Text>
                                        </View>
                                    </View>
                                </View> 
                            );
                        }) }
                        <View style={ styles.tableRowTotalMovement }>
                            <View style={ styles.tableColMovement }>
                                <View style={ styles.totalMovement }>
                                    <Text style={{ padding:5 }}>{`$ ${formatMoney(getTotals())}`}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowTitleSummary }>
                            <View style={ styles.tableColSummary }>
                                <View style={ styles.titleSummary }>
                                    <Text style={{ padding:5 }}>Resumen del mes</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSummaryOne }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellSummaryFirst }>
                                    <Text style={ styles.cellTitleSummary }>Saldo inicial</Text>
                                </View>
                                <View style={ styles.cellSummary }>
                                    <Text style={ styles.cellTitleSummary }>{`$ ${formatMoney(getFirstIncome())}`}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSummaryTwo }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellSummaryFirst }>
                                    <Text style={ styles.cellTitleSummary }>Depósitos y abonos</Text>
                                </View>
                                <View style={ styles.cellSummary }>
                                    <Text style={ styles.cellTitleSummary }>{`$ ${formatMoney(getSubTotals('income'))}`}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSummaryOne }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellSummaryFirst }>
                                    <Text style={ styles.cellTitleSummary }>Egresos y cargos</Text>
                                </View>
                                <View style={ styles.cellSummary }>
                                    <Text style={ styles.cellTitleSummary }>{`$ ${formatMoney(getSubTotals('egress'))}`}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSummaryTwo }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellSummaryFirst }>
                                    <Text style={ styles.cellTitleSummary }>Rendimientos</Text>
                                </View>
                                <View style={ styles.cellSummary }>
                                    <Text style={ styles.cellTitleSummary }>{`$ ${formatMoney(getSubTotals('yield'))}`}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={ styles.tableRowSummaryOne }>
                            <View style={{ flexDirection:'row', }}>
                                <View style={ styles.cellSummaryFirst }>
                                    <Text style={ styles.cellTitleFinalSummary }>Saldo final</Text>
                                </View>
                                <View style={ styles.cellSummary }>
                                    <Text style={ styles.cellTitleFinalSummary }>{`$ ${formatMoney(getTotals())}`}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
                </Document>
            </PDFViewer>
        </Fragment>
    )
}
