import React from 'react';
import '../styles/modal.css';
import { Spinner, Toast, ToastBody } from 'reactstrap';
import { changePassword, me } from '../services/customer';
// import Cookies from 'js-cookie';

export default function ModalPassword({userInfo, setUserInfo, setPasswordModal}) {

    const [actualPassword, setActualPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
    const [errorDialog, setErrorDialog] = React.useState('');
    const [loading, setLoading ] = React.useState(false);

    const validatePassword = e => {
        e.preventDefault();
        setLoading(true);
        if (newPassword === confirmNewPassword) {
            changePassword(actualPassword, newPassword, confirmNewPassword)
                .then(response => {
                    if (response) {
                        return me(userInfo.id);
                    } else {
                        setErrorDialog('Hubo un error al cambiar tu contraseña, intentalo más tarde.');
                        setTimeout(() => setErrorDialog(''), 3000);
                        return
                    }
                })
                .then(response => {
                    // Cookies.set('user',response);
                    setUserInfo(response);
                    setLoading(false);
                    setPasswordModal(false);
                })
                .catch(() => {
                    setErrorDialog('Tu contraseña actual no es correcta.');
                    setTimeout(() => setErrorDialog(''), 3000);
                })
        } else {
            setErrorDialog('Las contraseñas no coinciden.');
            setTimeout(() => setErrorDialog(''), 3000);
        }
    }

    return (
        <div className="modal">
            {
                (!!errorDialog) 
                    ?   <div className="rounded alert-dialog">
                            <Toast><ToastBody style={{backgroundColor:'red', color:'white',}}>{errorDialog}</ToastBody></Toast>
                        </div>
                    : null
            }
            <div className="modal-content">
                <span onClick={() => setPasswordModal(false)} style={{fontSize:'40px', marginLeft:20, margin:'0 !important',}}>&times;</span>   
                <div className="container">
                    <div className="row">
                        <div className="col-12" style={{textAlign:'center', marginBottom:10}}>
                            <h2>Es necesario cambiar tu contraseña.</h2>
                        </div>
                        <div className="col-12" style={{textAlign:'center'}}>
                            <form>
                                <p>Contraseña actual</p>
                                <input type="password" style={{marginBottom:10}} value={actualPassword} onChange={e => setActualPassword(e.target.value)} />
                                <p>Nueva contraseña</p>
                                <input type="password" style={{marginBottom:10}} value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                <p>Confirma tu nueva contraseña</p>
                                <input type="password" style={{marginBottom:10}} value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} />
                            </form>
                            <button style={{marginBottom:40}} onClick={validatePassword}>
                                {(loading === false) ? 'Guardar' : <Spinner className="loading" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
