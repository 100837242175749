import React from 'react';
import '../styles/summary.css';
import NumberFormat from 'react-number-format';
import domtoimage from 'dom-to-image';

export default function Summary({ movements, history }) {

    const [dataImage, setDataImage] = React.useState(null);

    const getTotals = () => {
        let total = 0;
        movements.forEach(item => {
            if (item.type === 'income') {
                total += item.amount
            } else if (item.type === 'egress') {
                total -= item.amount
            } else {
                total += item.amount
            }
        });
        return total;
    }

    const getIncome = () => {
        let total = 0
        movements.forEach(item => {
            if (item.type === 'income') {
                total += item.amount
            }
        });
        return total;
    }

    const getEgress = () => {
        let total = 0
        movements.forEach(item => {
            if (item.type === 'egress') {
                total += item.amount
            }
        });
        return total;
    }

    const getYield = () => {
        let total = 0
        movements.forEach(item => {
            if (item.type === 'yield') {
                total += item.amount
            }
        });
        return total;
    }

    const downloadPDF = () => {
        const report = document.getElementById('account');
        // report.style.width = "770px";
        // report.style.height = "500px";
        domtoimage.toPng(report, {quality:1})
            .then(function (dataUrl) {
                window.jsPDF = require('jspdf');
                const pdf =  window.jsPDF();
                pdf.addImage(dataUrl, 'PNG', 5, 10);
                pdf.save("download.pdf");
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    };

    React.useEffect(() => {
    },[]);

    return (
        <div className="center-summary col-12">
            <div className="summary mt-4 mb-4">
                <h2>Resúmen del mes</h2>
                <div className="wrapper">
                    <table className="table-summary">
                        <thead>
                            <tr>
                                <th colSpan="2">Totales</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="table-summary-border-right">Saldo inicial</td>
                                <td><NumberFormat value={(!!movements[0]) ? movements[0].amount.toFixed(2) : 0} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></td>
                            </tr>
                            <tr>
                                {/* ingresos */}
                                <td className="table-summary-border-right">Depósito y abonos</td>
                                <td><NumberFormat value={getIncome().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></td>
                            </tr>
                            <tr>
                                {/* egresos */}
                                <td className="table-summary-border-right">Egresos y cargos</td>
                                <td><NumberFormat value={getEgress().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></td>
                            </tr>
                            <tr>
                                <td className="table-summary-border-right">Rendimientos</td>
                                <td><NumberFormat value={getYield().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></td>
                            </tr>
                            <tr>
                                <td className="table-summary-border-right">Saldo final</td>
                                <td><b><NumberFormat value={getTotals().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-5">
                    {/* <button onClick={() => downloadPDF()}>Imprimir</button> */}
                    <button onClick={() => history.push('/estado')}>Imprimir</button>
                    {/* <a download="abcd.png" href={dataImage}>Descargar imagen</a> */}
                </div>
            </div>
        </div>
    )
}
