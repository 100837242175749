import React, { Fragment, useState } from 'react';
import Logo from '../assets/img/icons/logo.png';
import '../index.css';
import '../styles/login.css';
import { Spinner, Toast, ToastBody } from 'reactstrap';
import { login, loginPhone } from '../services/auth';
import { recoverPassword } from '../services/auth';

export default function Login({history}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading ] = useState(false);
    const [errorMessage, setErrorMessage ] = useState(false);
    const [errorDialog, setErrorDialog] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('red');

    const signIn = e => {
        e.preventDefault();
        setLoading(true);
        if (email !== '' && password !== '') {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                login(email.trim(), password.trim())
                    .then(() => {
                        setLoading(false);
                        history.push('/');
                    })
                    .catch(() => {
                        setLoading(false)
                        setBackgroundColor('red');
                        setErrorDialog('No se puede iniciar sesión, revisa tu correo o celular y contraseña.')
                        setTimeout(() => setErrorDialog(''), 3000);
                    });
            } else {
                loginPhone(email.trim(), password.trim())
                    .then(() => {
                        setLoading(false);
                        history.push('/');
                    })
                    .catch(() => {
                        setLoading(false)
                        setBackgroundColor('red');
                        setErrorDialog('No se puede iniciar sesión, revisa tu correo o celular y contraseña.')
                        setTimeout(() => setErrorDialog(''), 3000);
                    });
            }
        } else {
            setLoading(false)
            setErrorMessage(true);
        }
    }

    const validateEmail = e => {
        e.preventDefault();
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) && email !== '') {
            recoverPassword(email)
                .then(() => {
                    setBackgroundColor('green');
                    setErrorDialog('Se ha enviado un correo para restablecer tu contraseña.');
                })
                .catch(() => {
                    setBackgroundColor('red');
                    setErrorDialog('No se ha podido enviar el correo.');
                })
        } else {
            setBackgroundColor('red');
            setErrorDialog('El correo es invalido.');
        }
        setTimeout(() => setErrorDialog(''), 3000);
    }

    const ErrorMessage = () => (
        <div className="alert alert-danger message" role="alert">
            No se puede iniciar sesión, revisa tu correo y contraseña.
        </div>
    );

    return (
        <Fragment>
            <div className="gradient">
                <div className="wrapper-login">
                    {
                        (!!errorDialog) 
                            ?   <div className="rounded alert-dialog">
                                    <Toast><ToastBody style={{backgroundColor:`${backgroundColor}`, color:'white',}}>{errorDialog}</ToastBody></Toast>
                                </div>
                            : null
                    }
                    <div className="formContainer" id="formContent">
                        {/* <div className="alert alert-success message" role="alert">
                            <b>Correo: </b>admin@gmail.com<br/><b>Contraseña: </b> 123456
                        </div> */}
                        {errorMessage ? <ErrorMessage /> : null}
                        <div className="logo-container">
                            <div className="center">
                                <img src={Logo} id="icon" alt="User Icon" className="logoStyle" />
                            </div>
                        </div>
                        <form className="login-form">
                            <input type="text" id="email" name="email" placeholder="Correo o celular" value={email} onChange={e => setEmail(e.target.value)}/>
                            <input type="password" id="password" name="login" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)}/>
                            <button type="button" id="button" onClick={signIn} style={{padding:'0'}}>
                                {(loading === false) ? 'Iniciar sesión' : <Spinner className="loading" />}
                            </button>
                        </form>
                        <div id="formFooter">
                            <a className="" href="recuperar" onClick={validateEmail}>¿Olvidaste tu contraseña?</a>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
