import React from 'react';
import '../styles/period.css';
import "react-datepicker/dist/react-datepicker.css";
import { CUTOFF_DATE_FORMATTED } from '../services/constants';

export default function Period({userInfo, setShowModal}) {

    return (
        <>
            <div className="col-12 col-lg-6">
                <div className="mt-5">
                    <div className="wrapper">
                        <table className="table-period">
                            <thead>
                                <tr>
                                    <th colSpan="2">Información del periodo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-period-border-right">Periodo seleccionado</td>
                                    <td>
                                        <button onClick={() => setShowModal(true)}>Elegir fechas</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-period-border-right">Fecha de corte</td>
                                    <td>{CUTOFF_DATE_FORMATTED}</td>
                                </tr>
                                <tr>
                                    <td className="table-period-border-right">Número de cuenta</td>
                                    <td>{userInfo.account_number}</td>
                                </tr>
                                <tr>
                                    <td className="table-period-border-right">Número de referencia</td>
                                    <td>{userInfo.reference_number}</td>
                                </tr>
                                <tr>
                                    <td className="table-period-border-right">Número de cliente</td>
                                    <td>{userInfo.customer_number}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
        </>
    )
}
