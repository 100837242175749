import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './containers/Login';
import Account from './containers/Account';
import State from './containers/State';
import './App.css';
import Cookies from 'js-cookie';

function App() {

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Login" render={props => {
          if (!!Cookies.get('token')) {
            return <Redirect to="/" />
          }
          return <Login {...props}/>
        }} />
        <Route exact path="/" name="Inicio" render={props => {
          if (!Cookies.get('token')) {
            return <Redirect to="/login" />
          }
          return <Account {...props} />
        }} />
        <Route exact path="/estado" name="Estado de cuenta" render={props => {
          if (!Cookies.get('token')) {
            return <Redirect to="/login" />
          }
          return <State {...props} />
        }} />
      </Switch>
    </HashRouter>
  );
}

export default App;
