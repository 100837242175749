import React from 'react';
import { Navbar, Nav, DropdownButton, Dropdown } from 'react-bootstrap/';
import Logo from '../assets/img/icons/logo-full.png';
import '../styles/header.css';
import Cookies from 'js-cookie';

export default function Header({ userInfo, setPasswordModal, history }) {

    // const user = JSON.parse(Cookies.get('user'));

    return (
        <>
            <Navbar className="nav-bar" collapseOnSelect expand="lg">
                <Navbar.Brand className="nav-bar-title" href="#home"><img src={Logo} alt="Logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {/* <Nav.Link className="nav-bar-title" href="#"><b>Resumen</b></Nav.Link>
                        <Nav.Link className="nav-bar-title" href="#"><b>Imprimir</b></Nav.Link> */}
                    </Nav>
                    <Nav>
                        <DropdownButton alignRight title={<span className="account-button"><b>{`${userInfo.name}`}</b></span>} id="dropdown-menu-align-right" >
                            <Dropdown.Item eventKey="1" onClick={() => setPasswordModal(true)}>Cambiar contraseña</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="4" onClick={() => {
                                Cookies.remove('token');
                                Cookies.remove('user_id');
                                Cookies.remove('user');
                                Cookies.remove('dates');
                                history.push("/login");
                            }}>Cerrar sesión</Dropdown.Item>
                        </DropdownButton>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}
