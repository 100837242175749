var User = (function() {
    var user = JSON.parse(localStorage.getItem('user'));
  
    var getName = function() {
        if (!!user) {
            return `${user.name} ${user.last_name}`;
        } else {
            return '';
        }
    };

    var getToken = function() {
        if (!!user) {
            return `${user.token_type} ${user.access_token}`;
        } else {
            return '';
        }
    };

    var getId = function() {
        if (!!user) {
            return `${user.id}`;
        } else {
            return '';
        }
    }

    var getPasswordChanged = function() {
        if (!!user) {
            return user.password_changed;
        } else {
            return null;
        }
    };

    var getUserData = function() {
        if (!!user) {
            return user.user_data;
        } else {
            return null;
        }
    }
  
    return {
        getName: getName,
        getToken: getToken,
        getId: getId,
        getPasswordChanged: getPasswordChanged,
        getUserData:getUserData,
    }
  
  })();
  
  export default User;