import axios from 'axios';
import { API_ENVIROMENT_URL, CUTOFF_DATE_FORMATTED, CURRENT_DATE_FORMATTED } from './constants';
import { me } from './customer';
import { get } from './movements';
import Cookies from 'js-cookie';

export const login = (email, password) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + 'user/login';
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }
    axios.post(url, {email, password}, {headers:headers} )
        .then(response => {
            const {id, token_type, access_token} = response.data;
            let token = `${token_type} ${access_token}`;
            Cookies.set('token',token, { expires: 1 });
            Cookies.set('user_id', id, { expires: 1 });
            return me(id);
        })
        .then(response => {
            Cookies.set('user',response, { expires: 1 });
            resolve();
        })
        .catch(error => {
            reject();
        })
});

export const loginPhone = (phone, password) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + 'user/loginPhone';
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }
    axios.post(url, {phone, password}, {headers:headers})
        .then((res) => {
            const {id, token_type, access_token} = res.data;
            let token = `${token_type} ${access_token}`;
            Cookies.set('token',token, { expires: 1 });
            Cookies.set('user_id', id, { expires: 1 });
            return me(id);
        })
        .then((res) => {
            Cookies.set('user',res, { expires: 1 });
            resolve();
        })
        .catch(error => {
            reject();
        })
});

export const recoverPassword = (email) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + 'user/recover_password';
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }
    axios.post(url, { email }, { headers:headers } )
        .then(() => {
            resolve();
        })
        .catch(() => {
            reject();
        })
});
