import axios from 'axios';
import { API_ENVIROMENT_URL } from './constants';
import User from '../helpers/User';
import Cookies from 'js-cookie';

export const me = (id) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + `customer/get/${id}`;
    console.log('customer url -> ',url);
    const headers = {
        'Authorization': Cookies.get('token'),
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }
    axios.get(url,{ headers: headers})
        .then(response => {
            resolve(response.data.data);
        })
        .catch(error => {
            reject(error);
        })
});

export const changePassword = (current_password, password, password_confirmation) => new Promise((resolve, reject) => {
    let url = API_ENVIROMENT_URL + 'customer/changePassword';
    const headers = {
        'Authorization':Cookies.get('token'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }
    axios.post(url, {current_password, password, password_confirmation}, {headers:headers} )
        .then(response => {
            console.log(response.data.success);
            resolve(response.data.success);
        })
        .catch(error => {
            reject(error);
        })
});