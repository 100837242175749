import React from 'react';
import '../styles/modal.css';
import DatePicker from 'react-datepicker';
import { Toast, ToastBody } from 'reactstrap';
import Cookies from 'js-cookie';

export default function Modal({setShowModal, loadPeriod}) {

    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [errorDialog, setErrorDialog] = React.useState('');

    const validDates = () => {
        if (startDate.getTime() > endDate.getTime()) {
            setErrorDialog('Las fechas no son correctas.');
            setTimeout(() => setErrorDialog(''), 3000);
        } else {
            Cookies.set('dates',{startDate:startDate, endDate:endDate}, { expires: 1 });
            loadPeriod(startDate, endDate)
        }
    }

    return (
        <div className="modal">
            {
                (!!errorDialog) 
                    ?   <div className="rounded alert-dialog">
                            <Toast><ToastBody style={{backgroundColor:'red', color:'white',}}>{errorDialog}</ToastBody></Toast>
                        </div>
                    : null
            }
            <div className="modal-content">
                <span onClick={() => setShowModal(false)} style={{fontSize:'40px', marginLeft:20, margin:'0 !important',}}>&times;</span>   
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12" style={{color:'black', textAlign:'center', marginBottom:20}}>
                            <h2>Selecciona las fechas del periodo</h2>
                        </div>
                        <div className="col-sm-6" style={{textAlign:'center'}}>
                            <p>Fecha de inicio</p>
                            <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                        </div>
                        <div className="col-sm-6" style={{textAlign:'center'}}>
                            <p>Fecha final</p>
                            <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                        </div>
                        <div className="col-sm-12" style={{textAlign:'center', marginTop:40, marginBottom:20}}>
                            <button onClick={() => validDates()}>Ver periodo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
