import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import '../styles/movements.css';

export default function Movements({movements, userInfo, format}) {

    const getTotals = () => {
        let total = 0;
        movements.forEach(item => {
            if (item.type === 'income') {
                total += item.amount
            } else if (item.type === 'egress') {
                total -= item.amount
            } else {
                total += item.amount
            }
        });
        return total;
    }

    const setType = (type) => {
        switch (type) {
            case 'income':
                return 'Ingreso';
            case 'yield':
                return 'Rendimiento';
            default:
                return 'Egreso';
        }
    }

    return (
        <div className="col-12">
            <div className="movement mt-4">
                <h2>Movimientos</h2>
                <div className="wrapper-movement">
                    <table className="table-movement">
                        <thead>
                            <tr>
                                <th colSpan="4" style={{paddingLeft:'1rem', paddingTop:'1rem'}}>Datos: {`${userInfo.name}`} - {userInfo.customer_number}</th>
                            </tr>
                            <tr style={{borderBottom:'1px solid lightGray'}}>
                                <th colSpan="4" style={{paddingLeft:'1rem', paddingBottom:'1rem'}}>No. Cuenta: {userInfo.account_number}</th>
                            </tr>
                            <tr>
                                <th style={{textAlign:'center'}}>Fecha</th>
                                <th style={{textAlign:'center'}}>Concepto</th>
                                <th style={{textAlign:'center'}}>Tipo</th>
                                <th style={{textAlign:'center'}}>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {movements.map(item => {
                                return (
                                    <tr>
                                        <td style={{textAlign:'center'}}>{format(new Date(item.date), 'yyyy-MM-dd')}</td>
                                        <td style={{textAlign:'center'}}>{item.concept}</td>
                                        <td style={{ textAlign:'center'}}>{setType(item.type)}</td>
                                        <td className="expenses">
                                            <NumberFormat value={item.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} />
                                        </td>
                                        {/* <td className="income" style={{paddingRight:'0.5rem'}}>{(item.type === 'income' ? <NumberFormat value={item.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /> : null )}</td>
                                        <td className="yield" style={{paddingRight:'0.5rem'}}>{(item.type === 'yield' ? <NumberFormat value={item.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /> : null )}</td> */}
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="4" style={{paddingRight:'1rem',}}>Total: <NumberFormat value={getTotals().toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} /></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
