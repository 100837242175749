import React from 'react';
import '../styles/profile.css';

export default function Profile({userInfo}) {

    return (
        <div className="col-12 col-lg-6">
            <div className="mt-5">
                <table className="table-profile">
                    <tbody>
                        <tr>
                            <th colSpan="2">{`${userInfo.name}`}</th>
                        </tr>
                        <tr>
                            <td><b>RFC: </b>{userInfo.rfc}</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><b>Domicilio: </b>{userInfo.address}</td>
                        </tr>
                        <tr>
                            <td colSpan="2"><b>Colonia: </b>{userInfo.colony}</td>
                        </tr>
                        <tr>
                            <td><b>Pais: </b>{userInfo.country}</td>
                        </tr>
                        <tr>
                            <td><b>Ciudad: </b>{`${userInfo.city}, ${userInfo.state}`}</td>
                            <td className="pl-5"><b>CP: </b>{userInfo.postal_code}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
