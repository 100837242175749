import React from 'react';
import Cookies from 'js-cookie';

// Material ui components
import CircularProgress from '@material-ui/core/CircularProgress';

// Api functions
import { me } from '../services/customer';
import { get } from '../services/movements';
import { getInitMovements } from '../services/movements';

// React Components
import Header from '../components/Header';
import Profile from '../components/Profile';
import Period from '../components/Period';
import Movements from '../components/Movements';
import Summary from '../components/Summary';
import Modal from '../components/Modal';
import ModalPassword from '../components/ModalPassword';

export default function Account({history}) {

    const [from_date, setFrom_date] = React.useState(new Date())
    const [to_date, setTo_date] = React.useState(new Date())
    const [movements, setMovements] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [passwordModal, setPasswordModal] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({});
    const [showAccount, setShowAccount] = React.useState(false);

    const loadPeriod = (starDate, endDate) => {
        setFrom_date(format(starDate, 'yyyy-MM-dd'))
        setTo_date(format(endDate, 'yyyy-MM-dd'))
        get(format(starDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'), userInfo.id)
        .then(response => {
            // Cookies.set('dates',{startDate:format(starDate, 'yyyy-MM-dd'), endDate:format(endDate, 'yyyy-MM-dd')}, { expires: 1 });
            setMovements(response);
            setShowModal(false)
        })
        .catch(error => {
            console.log(error);
        })
    }

    const format = (x, y) => {
        var z = {
            M: x.getMonth() + 1,
            d: x.getDate(),
            h: x.getHours(),
            m: x.getMinutes(),
            s: x.getSeconds()
        };
        y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
            return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
        });
    
        return y.replace(/(y+)/g, function(v) {
            return x.getFullYear().toString().slice(-v.length)
        });
    }

    const print = () => {

    }

    React.useEffect(() => {
        const id = Cookies.get('user_id');
        if (id) {
            me(id)
                .then((res) => {
                    if (res.password_changed === 0) {
                        setPasswordModal(true);
                    }
                    setUserInfo(res);
                    return getInitMovements(id);                    
                })
                .then((res) => {
                    console.log(res);
                    if (res.length > 0) {
                        const firstItem = res[0];
                        console.log(res.length-1);
                        const lastItem = res[res.length-1];
                        Cookies.set('dates',{startDate:firstItem.date, endDate:lastItem.date}, { expires: 1 });
                    }
                    setMovements(res);
                    setShowAccount(true);
                })
                .catch((err) => {
                    console.log('user error -> ', err);
                });
        }
	},[])

    return (
        <>
            {(showAccount)
                ?
                    <>
                        <Header userInfo={userInfo} setPasswordModal={setPasswordModal} history={history} />
                        <div className="container">
                            <div style={{ margin:'auto',}}>
                                <div id="account" className="row">
                                    <Profile userInfo={userInfo} />
                                    <Period userInfo={userInfo} setShowModal={setShowModal} />
                                    <Movements movements={movements} userInfo={userInfo} format={format} />
                                    <Summary print={print} movements={movements} history={history} />
                                    { (showModal) ? <Modal setShowModal={setShowModal} loadPeriod={loadPeriod} /> : null }
                                    { (passwordModal) ? <ModalPassword userInfo={userInfo} setUserInfo={setUserInfo} setPasswordModal={setPasswordModal}/> : null }
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress
                            style={{ 
                                color: '#09345B',
                                height: 30,
                                width: 30,
                                marginTop: 40,
                            }} />
                    </div>
            }
        </>
    )
}
