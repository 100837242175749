let api_sandbox = "http://174.138.46.119/fobe-api/public/";
let api_product = "https://fobe-system.xyz/froma-api/public/";
let api_localhost = "http://localhost:8000/";

let date = new Date()
let month = ((date.getMonth() + 1) > 9) ? date.getMonth() + 1 : '0'+(date.getMonth() + 1)

export const API_ENVIROMENT_URL = api_product;

export const CUTOFF_DATE_FORMATTED = `${date.getFullYear()}-${month}-06`;
export const CURRENT_DATE_FORMATTED = `${date.getFullYear()}-${month}-${date.getDate()}`;
